<template>
  <div
    class="rounded-none p-4 mt-4 relative mr-4"
    v-if="infoMessage && !dismissed && headerIsEnabled"
    :class="{
      'bg-yellow-200': infoMessageSettings.topHeaderColor === 'yellow',
      'bg-red-200': infoMessageSettings.topHeaderColor === 'red',
      'bg-blue-200': infoMessageSettings.topHeaderColor === 'blue',
      'bg-green-200': infoMessageSettings.topHeaderColor === 'green'
    }"
  >
    <font-awesome-icon
      @click="dismissHeader"
      class="absolute -right-2  -top-2 text-xl cursor-pointer"
      :icon="['fal', `times-circle`]"
      v-if="infoMessageSettings.topHeaderDismissible"
    />
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          v-if="infoMessageSettings.topHeaderIcon === 'info'"
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
            clip-rule="evenodd"
          />
        </svg>
        <font-awesome-icon
          v-else-if="infoMessageSettings.topHeaderIcon === 'success'"
          class="text-2xl"
          :icon="['fal', `check`]"
        />
        <font-awesome-icon
          v-else-if="infoMessageSettings.topHeaderIcon === 'error'"
          class="text-2xl"
          :icon="['fal', `times-circle`]"
        />
        <font-awesome-icon
          v-else-if="infoMessageSettings.topHeaderIcon === 'warn'"
          class="text-2xl"
          :icon="['fal', `exclamation-circle`]"
        />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <div class="text-base font-medium text-gray-800 ">
          {{ infoMessage }}
        </div>
        <div class="mt-3 text-sm md:mt-0 md:ml-6">
          <a
            v-if="infoMessageSettings.showTopHeaderLink"
            :href="infoMessageSettings.topHeaderLinkHref"
            class="whitespace-nowrap font-medium text-atlas-blue hover:text-blue-600"
            >{{ infoMessageSettings.topHeaderLinkText }}
            <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authMapGetters } from "@/store/modules/auth";
import { userMapActions, userMapMutations } from "@/store/modules/user";
export default {
  props: {
    infoMessageSettings: {
      default() {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      dismissed: false
    };
  },
  computed: {
    headerNotificationDismissed() {
      return this.getCurrentUser?.headerNotificationDismissed || false;
    },
    headerIsEnabled() {
      if (
        this.infoMessageSettings.topHeaderEnabled &&
        !this.infoMessageSettings.isScheduled
      ) {
        return true;
      }

      if (
        this.infoMessageSettings.topHeaderEnabled &&
        this.infoMessageSettings.isScheduled &&
        new Date(this.infoMessageSettings.startTime) <= new Date() &&
        new Date(this.infoMessageSettings.endTime) >= new Date()
      ) {
        return true;
      }
      return false;
    },
    ...authMapGetters(["getCurrentUserId", "getCurrentUser"]),
    infoMessage: function() {
      return this.infoMessageSettings
        ? this.infoMessageSettings.topHeaderMessage
        : "";
    }
  },
  watch: {
    headerNotificationDismissed: {
      handler() {
        this.dismissed = this.headerNotificationDismissed;
      },
      immediate: true
    }
  },
  methods: {
    ...userMapMutations(["SET_EDIT_USER"]),
    ...userMapActions(["updateUser"]),
    async dismissHeader() {
      await this.updateUser({
        id: this.getCurrentUserId,
        update: {
          headerNotificationDismissed: true
        }
      });
      this.dismissed = true;
    }
  }
};
</script>

<style></style>
